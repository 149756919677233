import React, { useState } from 'react';
import './PricingYourHome.css';
import StickyBottomBar from './StickyBottomBar';

const PerfectTimeBuy = () => {
  const [searchParams, setSearchParams] = useState({
    minPrice: '',
    maxPrice: '',
    search: ''
  });

  const cities = [
    'Toronto', 'Mississauga', 'Brampton', 'Oakville', 'Hamilton', 
    'Vaughan', 'Waterloo', 'London', 'Markham', 'Ajax', 'Aurora', 
    'Barrie', 'Brock', 'Burlington', 'Caledon', 'Cambridge', 
    'Clarington', 'Collingwood', 'Erin', 'Georgina', 'Grimsby', 
    'Innisfil', 'King', 'Kingston', 'Lincoln', 'Midland', 'Ottawa', 
    'Milton', 'Newmarket', 'Niagara-on-the-lake', 'Orangeville', 
    'Orillia', 'Oshawa', 'Peterborough', 'Pickering', 'Tiny', 
    'Welland', 'Whitby', 'Whitchurch'
  ];

  const styles = {
    container: {
      maxWidth: '1500px',
      margin: '0 auto',
      padding: '0rem 0rem',
    },
    grid: {
      gap: '2rem',
    },
    mainContent: {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
    },
    card: {
      background: 'white',
      borderRadius: '8px',
      padding: '1rem',
      marginBottom: '0rem',
    },
    sidebarCard: {
      background: '#f3f4f6',
      borderRadius: '8px',
      padding: '1.5rem',
      marginBottom: '1rem',
    },
    sidebar: {
      position: 'sticky',
      top: '1rem',
      marginTop: '2rem',
      width: '300px',
      minWidth: '300px'
    },
    list: {
      listStyleType: 'none',
      marginBottom: '1rem',
      paddingLeft: '0',
      fontSize: '1rem',
      lineHeight: '1.5',
    },
    priceInputContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      marginBottom: '0.5rem',
    },
    priceInput: {
      width: '100%',
      padding: '0.75rem',
      borderRadius: '0.25rem',
      border: '1px solid #e2e8f0',
      backgroundColor: 'white',
      fontSize: '1rem',
    },
    searchInput: {
      width: '100%',
      padding: '0.75rem',
      marginBottom: '0rem',
      borderRadius: '0.25rem',
      border: '1px solid #e2e8f0',
      backgroundColor: 'white',
      fontSize: '1rem',
    },
    cityList: {
      marginTop: '1rem',
    },
    cityLink: {
      display: 'block',
      padding: '0.5rem',
      color: '#2563eb',
      textDecoration: 'none',
      fontSize: '1.25rem',
      borderRadius: '0.25rem',
      '&:hover': {
        backgroundColor: '#f0f9ff',
      }
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const queryString = new URLSearchParams({
      city: 'All',
      community: 'null',
      minPrice: searchParams.minPrice || '0',
      maxPrice: searchParams.maxPrice || '10000000',
      bedrooms: 'Any',
      bathrooms: 'Any',
      search: searchParams.search || '',
      page: '1'
    }).toString();
    window.location.href = `/listings?${queryString}`;
  };

  const handleInputChange = (e) => {
    setSearchParams({
      ...searchParams,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="pricing-container" style={styles.container}>
      <div className="pricing-grid" style={styles.grid}>
        <div className="pricing-main-content" style={styles.mainContent}>
          <section className="pricing-hero-card" style={styles.card}>
            <h1 className="pricing-title">Why Now is the Perfect Time to Buy in the GTA</h1>
            <p className="pricing-text">
              The GTA real estate market is offering unique opportunities for buyers. Supported by solid data and expert analyses, here's why now is the time to act:
            </p>
          </section>

          <div className="pricing-content-card" style={styles.card}>
            <h2 className="pricing-subtitle">1. Elevated Inventory Levels Provide More Choices</h2>
            <p className="pricing-text">
              As of July 2024, Toronto's housing inventory reached its highest point in over a decade, with listings increasing by nearly 25% compared to the same period in the previous year. (Reuters)
            </p>
            <p className="pricing-text">
              This surge in available properties offers buyers more selection and the opportunity for better deals, as sellers may be more willing to negotiate.
            </p>
          </div>

          <div className="pricing-content-card" style={styles.card}>
            <h2 className="pricing-subtitle">2. Reduced Foreign Investor Activity Stabilizes the Market</h2>
            <p className="pricing-text">
              The 20% Foreign Buyers Tax and the temporary ban on foreign homebuyers have significantly decreased foreign investor participation in the GTA market by almost 50%. (Reuters)
            </p>
            <p className="pricing-text">
              With fewer investors in the market, local end-user buyers can benefit from a more balanced market with stabilized prices and less competition.
            </p>
          </div>

          <div className="pricing-content-card" style={styles.card}>
            <h2 className="pricing-subtitle">3. Anticipated Housing Shortages by 2026</h2>
            <p className="pricing-text">
              Ontario needs to build 1.5 million homes by 2030 to meet demand, but current construction rates fall short by over 30,000 units annually, leading to a housing shortage projected by 2026. (CMHC Housing Supply Report)
            </p>
            <p className="pricing-text">
              Buying now allows buyers to secure a property before shortages push prices higher and competition intensifies.
            </p>
          </div>

          <div className="pricing-content-card" style={styles.card}>
            <h2 className="pricing-subtitle">4. End-User Buyers Have a Competitive Advantage</h2>
            <p className="pricing-text">
              The decline in investor activity has shifted the market to favor end-user buyers, giving them access to more properties without investor competition. (Reuters)
            </p>
            <p className="pricing-text">
              This shift provides buyers with more time to make informed decisions and negotiate better prices.
            </p>
          </div>

          <div className="pricing-content-card" style={styles.card}>
            <h2 className="pricing-subtitle">5. Long-Term Market Growth Potential</h2>
            <p className="pricing-text">
              GTA home prices are expected to increase by 10% year-over-year in Q4 2024, despite recent market fluctuations. (Newswire)
            </p>
            <p className="pricing-text">
              Purchasing now positions buyers to benefit from future price appreciation and long-term equity growth.
            </p>
          </div>

          <div className="pricing-content-card" style={styles.card}>
            <h2 className="pricing-subtitle">6. Mortgage Rate Stabilization</h2>
            <p className="pricing-text">
              The Bank of Canada is expected to stabilize interest rates in late 2025 or early 2026, following recent reductions. (Reuters)
            </p>
            <p className="pricing-text">
              Locking in today's rates helps protect you from future rate increases, while giving you the option to refinance at lower rates in the future.
            </p>
          </div>

          <div className="pricing-content-card" style={{...styles.card, textAlign: 'center'}}>
            <h2 className="pricing-subtitle">Take Action Now</h2>
            <p className="pricing-text">
              With increased inventory, reduced foreign buyer competition, potential housing shortages, and the possibility of lower interest rates, now is an ideal time to buy.
            </p>
            <p className="pricing-text">
              Contact Modern Solution Realty to secure your future, find your dream home, and take advantage of our $5,000 cashback offer. Let's make your homeownership goals a reality!
            </p>
          </div>
        </div>

        <div className="pricing-sidebar" style={styles.sidebar}>
          <div className="pricing-search-card" style={styles.sidebarCard}>
            <h3 className="pricing-sidebar-title">Search Listings</h3>
            <form onSubmit={handleSearch}>
              <div className="pricing-price-inputs" style={styles.priceInputContainer}>
                <div>
                  <input
                    type="number"
                    name="minPrice"
                    placeholder="Min Price"
                    className="pricing-input"
                    style={styles.priceInput}
                    value={searchParams.minPrice}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <input
                    type="number"
                    name="maxPrice"
                    placeholder="Max Price"
                    className="pricing-input"
                    style={styles.priceInput}
                    value={searchParams.maxPrice}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <input
                type="text"
                name="search"
                placeholder="Search by Address"
                className="pricing-search-input"
                style={styles.searchInput}
                value={searchParams.search}
                onChange={handleInputChange}
              />
              <button type="submit" className="pricing-search-button">
                Search
              </button>
            </form>
          </div>

          <div className="pricing-cities-card" style={styles.sidebarCard}>
            <h3 className="pricing-sidebar-title">Search by City</h3>
            <div className="city-list" style={styles.cityList}>
              {cities.map((city) => (
                <a
                  key={city}
                  href={`/listings?city=${city}`}
                  className="city-link"
                  style={styles.cityLink}
                >
                  {city}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <StickyBottomBar />
    </div>
  );
};

export default PerfectTimeBuy;