import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import SEO from './SEO';
import GoogleReviews from './GoogleReviews';
import Footer from './Footer';
import CityWidget from './CityWidget';
import thestarLogo from '../assets/images/thestar-logo-2.png';
import './About.css';

// You'll need to create these icons or import them from your assets
import icon1 from '../assets/images/icon-1.webp';
import icon2 from '../assets/images/icon-2.webp';
import icon3 from '../assets/images/icon-3.webp';
import icon4 from '../assets/images/icon-4.webp';
import icon5 from '../assets/images/icon-5.webp';

// Define counters directly in the component or import from a separate file
const counters = [
  {
    id: 'properties-sold', // This ID must match exactly with Home.js navigation
    value: '2,500+ Properties Sold',
    text: 'Properties Sold',
    icon: icon3,
    details: 'Modern Solution has successfully facilitated the sale of over 2,500 properties, demonstrating our expertise and commitment to helping clients achieve their real estate goals. Our team of experienced professionals has consistently delivered results across diverse market conditions, ensuring optimal outcomes for our sellers.'
  },
  {
    id: 'buyer-rebates',
    value: '$1.1 Million+ Buyers Received in Cashback',
    text: 'Buyers Received in Cashback',
    icon: icon4,
    details: 'Through our innovative buyer rebate program, we\'ve given back over $1.1 million to our buying clients. This significant milestone reflects our commitment to making homeownership more affordable while providing top-tier service. Our $5,000 cashback offer continues to help buyers across the Greater Toronto Area.'
  },
  {
    id: 'seller-savings',
    value: '$20.15 Million+ Sellers Saved on Commission',
    text: 'Sellers Saved on Commission',
    icon: icon5,
    details: 'Our 1% commission model has resulted in over $20.15 million in savings for our selling clients compared to traditional real estate commission structures. This revolutionary approach demonstrates our commitment to providing value while maintaining exceptional service standards.'
  },
  {
    id: 'market-experience',
    value: '9+ Years of Experience',
    text: 'Years of Market Experience',
    icon: icon1,
    details: 'With over 9 years of experience in the real estate market, Modern Solution has developed deep expertise in local market dynamics, pricing strategies, and negotiation techniques. Our seasoned team brings this wealth of knowledge to every transaction.'
  },
  {
    id: 'client-satisfaction',
    value: '4.9/5 Star Rating',
    text: 'Client Satisfaction',
    icon: icon2,
    details: 'Our commitment to client satisfaction is reflected in our outstanding 4.9/5 star rating on Google Reviews. This rating is based on hundreds of verified client reviews, showcasing our dedication to providing exceptional service and results for every client.'
  }
];

const About = () => {
  const [expandedItems, setExpandedItems] = useState({});
  const location = useLocation();
  const counterRefs = useRef([]);

  // Function to toggle the expansion of a counter
  const toggleExpand = (index) => {
    setExpandedItems((prev) => {
      const newState = {};
      // Collapse all other counters
      counters.forEach((_, i) => {
        newState[i] = i === index ? !prev[index] : false;
      });
      return newState;
    });
  };

  useEffect(() => {
    if (location.hash) {
      const hash = location.hash.substring(1); // Remove the '#' character
      console.log('Hash detected:', hash);
      console.log('Available counters:', counters.map(c => c.id));
      const index = counters.findIndex((counter) => counter.id === hash);
      console.log('Found index:', index);
      
      if (index !== -1) {
        console.log('Attempting to expand counter at index:', index);
        // Expand immediately to ensure it happens
        setExpandedItems(prev => {
          const newState = {};
          counters.forEach((_, i) => {
            newState[i] = i === index;
          });
          return newState;
        });
        
        // Then scroll to it
        requestAnimationFrame(() => {
          if (counterRefs.current[index]) {
            counterRefs.current[index].scrollIntoView({ 
              behavior: 'smooth', 
              block: 'center'
            });
          }
        });
      }
    }
  }, [location.hash, counters]);

  return (
    <div className="about-page">
      <SEO
        title="About Modern Solution | Leading Real Estate Agency in Toronto"
        description="Learn about Modern Solution's innovative approach to real estate, our commitment to client success, and our industry-leading 1% commission structure."
        keywords={[
          'Modern Solution Realty',
          'Toronto real estate agency',
          'low commission real estate',
          'about Modern Solution',
          '1% commission realty',
        ]}
        ogImage="https://modernsolution.ca/about-image.jpg"
        url="https://modernsolution.ca/about"
      />

      {/* First Full-Width Text Section */}
      <div className="full-width-text-section animate-on-scroll">
        <div className="content-image-section">
          <div className="text-content">
            <h2>About Modern Solution</h2>
            <p>
              Modern Solution Realty Inc., headquartered in Mississauga, Ontario, has been transforming the real estate landscape since 2014 by offering clients both substantial savings and exceptional service.
            </p>
            <p>
              Modern Solution is revolutionizing the real estate industry with our innovative approach to buying and selling homes. Our commitment to providing exceptional service while maintaining our industry-leading 1% commission structure has helped thousands of clients achieve their real estate goals.
            </p>
            <p>
              Founded on the principles of transparency, efficiency, and client success, we've grown to become one of Toronto's most trusted real estate agencies. Our team of experienced professionals combines deep market knowledge with cutting-edge technology to deliver outstanding results for every client.
            </p>
          </div>
          <div className="image-content">
            <img
              src={require('../assets/images/thestar-office.webp')}
              alt="Modern Solution Office"
              className="about-image"
              loading="lazy"
            />
          </div>
        </div>
      </div>

      {/* Counter Section */}
      <div className="counter-section">
        <div className="counter-row">
          {counters.slice(0, 3).map((counter, index) => (
            <div 
              key={counter.id} 
              id={counter.id}
              className={`counter-box ${expandedItems[index] ? 'expanded' : ''}`}
              ref={(el) => (counterRefs.current[index] = el)}
            >
              <div className={`counter-content ${expandedItems[index] ? 'hidden' : ''}`}>
                <img 
                  src={counter.icon} 
                  alt={`icon-${index + 1}`} 
                  className="counter-icon" 
                  loading="lazy" 
                />
                <h3>{counter.value}</h3>
                <p>{counter.text}</p>
                <button 
                  className="read-more-btn"
                  onClick={() => toggleExpand(index)}
                  aria-expanded={expandedItems[index] ? 'true' : 'false'}
                  aria-controls={`details-${counter.id}`}
                >
                  Read More
                </button>
              </div>
              <div 
                className={`counter-details ${expandedItems[index] ? 'visible' : ''}`} 
                id={`details-${counter.id}`} 
                role="region" 
                aria-labelledby={`heading-${counter.id}`}
              >
                <button 
                  className="close-btn"
                  onClick={() => toggleExpand(index)}
                  aria-label="Close details"
                >
                  ×
                </button>
                <p>{counter.details}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="counter-row">
          {counters.slice(3, 5).map((counter, index) => (
            <div 
              key={counter.id} 
              id={counter.id}
              className={`counter-box ${expandedItems[index + 3] ? 'expanded' : ''}`}
              ref={(el) => (counterRefs.current[index + 3] = el)}
            >
              <div className={`counter-content ${expandedItems[index + 3] ? 'hidden' : ''}`}>
                <img 
                  src={counter.icon} 
                  alt={`icon-${index + 4}`} 
                  className="counter-icon" 
                  loading="lazy" 
                />
                <h3>{counter.value}</h3>
                <p>{counter.text}</p>
                <button 
                  className="read-more-btn"
                  onClick={() => toggleExpand(index + 3)}
                  aria-expanded={expandedItems[index + 3] ? 'true' : 'false'}
                  aria-controls={`details-${counter.id}`}
                >
                  Read More
                </button>
              </div>
              <div 
                className={`counter-details ${expandedItems[index + 3] ? 'visible' : ''}`} 
                id={`details-${counter.id}`} 
                role="region" 
                aria-labelledby={`heading-${counter.id}`}
              >
                <button 
                  className="close-btn"
                  onClick={() => toggleExpand(index + 3)}
                  aria-label="Close details"
                >
                  ×
                </button>
                <p>{counter.details}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Additional Text Section */}
      <div className="full-width-text-section animate-on-scroll">
        <div className="additional-text-section">
          <div className="additional-box">
            <h3>Unmatched Value</h3>
            <p>
              When you list your property with us, you benefit from our competitive 1% commission model, which has collectively saved our clients over $20 million in commissions.
            </p>
          </div>
          <div className="additional-box">
            <h3>Exceptional Service</h3>
            <p>
              Our team of full-time, dedicated professionals consistently outperforms the competition, achieving a remarkable 4-to-1 success ratio. We handle all advertising responsibilities, allowing our agents to focus solely on delivering exceptional service to clients. This streamlined approach ensures that when you list your property with us, a team of experienced professionals is dedicated to your success, with management closely overseeing each transaction to maintain the highest standards of service.
            </p>
          </div>
          <div className="additional-box">
            <h3>Client Testimonials</h3>
            <p>
              Our clients' satisfaction is evident in their glowing reviews. For instance, one client remarked, "We decided to go with Modern Solution Realty based on the lower commission and are very glad we did. Hannah was our agent and was great to work with. Very friendly and professional service, and she got us the results we were hoping for."
            </p>
          </div>
          <div className="additional-box">
            <h3>Community Engagement</h3>
            <p>
              We actively engage with our community through various platforms, sharing updates and insights related to the real estate market. Our YouTube channel features informative content, and our Facebook page keeps followers informed about the latest market trends and company news.
            </p>
          </div>
          <div className="additional-paragraph">
            <p>
              At Modern Solution Realty Inc., we pride ourselves on offering a modern, efficient, and client-focused real estate experience, ensuring that your property journey is both successful and rewarding.
            </p>
          </div>
        </div>
      </div>

      {/* Google Reviews Section */}
      <div className="google-reviews-section" id="google-reviews">
        <h2>Our Google Reviews</h2>
        <GoogleReviews />
      </div>

      {/* City Links Section */}
      <div className="full-width-text-section animate-on-scroll">
        <div className="city-links-section">
          <CityWidget />
        </div>
      </div>

      {/* Toronto Star Section */}
      <div className="toronto-star-wrapper">
        <div className="toronto-star-container">
          <div className="home-page-toronto-star">
            <img src={thestarLogo} alt="The Star Logo" className="thestar-logo" loading="lazy" />
            <h2 className="toronto-star-heading">Modern Solution Featured in Toronto Star</h2>
            <p className="toronto-star-paragraph">
              In a real estate world where outdated commission rates have long been the norm,
              Modern Solution Realty Inc. is proud to offer a refreshing and cost-effective
              alternative.
              
              Traditional commission structures, often hovering around five to six per cent, have
              burdened home sellers with exorbitant fees. For instance, selling a $1,000,000 home with
              a standard five per cent commission could cost a staggering $50,000 — an expense that
              seems increasingly unreasonable in today's dynamic market.
            </p>
            <a
              href="https://www.thestar.com/sponsored-sections/a-modern-solution-approach-to-real-estate-with-one-per-cent-commission/article_1ca3e32e-6eec-11ef-84ea-53f224f162c8.html"
              target="_blank"
              rel="noopener noreferrer"
              className="read-more-link"
            >
              Read More
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default About;