import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SEO from './SEO';
import { AiFillStar } from 'react-icons/ai';
import { FaPlay, FaPhone, FaEnvelope } from 'react-icons/fa';
import googleLogo from '../assets/images/google-logo.webp';
import Footer from './Footer';
import './Home.css';
import ListingWidget from './ListingWidget';
import CityWidget from './CityWidget';
import icon1 from '../assets/images/icon-1.webp';
import icon2 from '../assets/images/icon-2.webp';
import icon3 from '../assets/images/icon-3.webp';
import icon4 from '../assets/images/icon-4.webp';
import icon5 from '../assets/images/icon-5.webp';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from '../services/axios';
import thestarLogo from '../assets/images/thestar-logo-2.png';
import ContactForm from './ContactForm';
import googleGLogo from '../assets/images/google-G.png';
import telephoneIcon from '../assets/icons/telephone.svg';
import emailIcon from '../assets/icons/email.svg';
import saleIcon from '../assets/icons/sale.svg';
import buyIcon from '../assets/icons/buy.svg';

import StickyBottomBar from './StickyBottomBar';


const Home = () => {
  const [propertyListings, setPropertyListings] = useState([]);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const response = await axios.get('/listings');
        const data = response.data; // Ensure you access the data correctly
        setPropertyListings(data);
      } catch (error) {
        console.error('Error fetching listings:', error);
      }
    };

    fetchListings();
  }, []);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    const elementsToAnimate = document.querySelectorAll('.fade-in');
    elementsToAnimate.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      elementsToAnimate.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  const counters = [
    { value: '2,500+ Properties Sold', text: 'Properties Sold', icon: icon3 },
    { value: '$1.1 Million+ Buyers Received in Cashback', text: 'Buyers Received in Cashback', icon: icon4 },
    { value: '$20.15 Million+ Sellers Saved on Commission', text: 'Customer Support', icon: icon5 },
  ];

  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsVideoPlaying(true);
    }
  };

  return (
    <div className="home-page">
      <SEO
        title="Sell for 1% Commission | Low Commission Real Estate | 1 Percent List Realty"
        description="Sell your home for just 1% commission with Modern Solution. Enjoy low commission real estate services and get $5000 cashback when you buy. Call now!"
        keywords={[
          '1% commission real estate',
          'low commission real estate',
          'sell for 1 percent',
          '1 Percent List Realty',
          '$5000 cashback',
          'Modern Solution Realty',
        ]}
        ogImage="https://modernsolution.ca/homepage-image.jpg"
        url="https://modernsolution.ca/"
      />

      <div className="fullwidth-container">
        <div className="video-and-icons-section fade-in">
          <div className="video-container">
            {!isVideoPlaying && (
              <div className="unique-play-button" onClick={handlePlayVideo}>
                <FaPlay className="unique-play-icon" />
              </div>
            )}
            <video
              ref={videoRef}
              width="100%"
              height="100%"
              controls
              playsInline={true}
              preload="metadata"
              poster={require('../assets/images/video-poster-2.png')}
              onPlay={() => setIsVideoPlaying(true)}
              onPause={() => setIsVideoPlaying(false)}
            >
              <source src={require('../assets/videos/video.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="icon-boxes-container">
            <div
              className="icon-box fade-in phone-icon"
              onClick={() => navigate('/contact')}
              style={{ cursor: 'pointer' }}
            >
              <div className="icon-title-container">
                <div className="contact-icon-container phone-email-icon">
                  <img src={emailIcon} alt="Email Icon" className="contact-icon" />
                </div>
              </div>
            </div>

            <div
              className="icon-box fade-in"
              onClick={() => (window.location.href = 'tel:9058975000')}
              style={{ cursor: 'pointer' }}
            >
              <div className="icon-title-container">
                <div className="contact-icon-container phone-email-icon">
                  <img src={telephoneIcon} alt="Phone Icon" className="contact-icon" />
                </div>
                <p className="phone-text">(905) 897-5000</p>
              </div>
            </div>

            <div
              className="icon-box fade-in"
              onClick={() => {
                navigate('/about#google-reviews');
                setTimeout(() => {
                  const element = document.getElementById('google-reviews');
                  if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  }
                }, 100);
              }}
              style={{ cursor: 'pointer' }}
            >
              <div className="google-icon-title-container">
                <div className="counter-icon google-icon-container">
                  <div className="stars-row">
                    <AiFillStar className="star-icon" />
                    <AiFillStar className="star-icon" />
                    <AiFillStar className="star-icon" />
                    <AiFillStar className="star-icon" />
                    <AiFillStar className="star-icon" />
                  </div>
                  <img src={googleLogo} alt="Google Logo" className="google-logo" />
                </div>
                <h3 className="icon-text-google">Based on 226 reviews</h3>
              </div>
            </div>

            <div
              className="icon-box fade-in"
              onClick={() => navigate('/about#properties-sold')}
              style={{ cursor: 'pointer' }}
            >
              <div className="google-icon-title-container">
                <h3 className="icon-text"><span className='blurb-text-desktop'>2,500+</span> <br /> Properties Sold</h3>
              </div>
            </div>

            <div
              className="icon-box fade-in"
              onClick={() => navigate('/about#buyer-rebates')}
              style={{ cursor: 'pointer' }}
            >
              <div className="google-icon-title-container">
                <h3 className="icon-text"><span className='blurb-text-desktop'>$1.1 Million+</span> <br />Buyers Received in Rebate</h3>
              </div>
            </div>

            <div
              className="icon-box fade-in"
              onClick={() => navigate('/about#seller-savings')}
              style={{ cursor: 'pointer' }}
            >
              <div className="google-icon-title-container">
                <h3 className="icon-text"><span className='blurb-text-desktop'>$20 Million+</span> <br />Sellers Saved on Commission</h3>
              </div>
            </div>
          </div>

          <div className="google-reviews-fullwidth">
            <div
              className="google-icon-container"
              onClick={() => navigate('/about#google-reviews')}
              style={{ cursor: 'pointer' }}
            >
              <div className="stars-row">
                <AiFillStar className="star-icon" />
                <AiFillStar className="star-icon" />
                <AiFillStar className="star-icon" />
                <AiFillStar className="star-icon" />
                <AiFillStar className="star-icon" />
              </div>
              <img src={googleLogo} alt="Google Logo" className="google-logo" />
              <h3 className="icon-text">Based on 226 reviews</h3>
            </div>
          </div>
        </div>

        {/* Moved mobile-icon-container below video-and-icons-section */}
        <div className="mobile-icon-container">
          <div
            className="icon-box-mobile"
            onClick={() => navigate('/about#properties-sold')}
            style={{ cursor: 'pointer' }}
          >
            <div className="google-icon-title-container-mobile">
              <h3 className="icon-text-mobile">
                <span className="blurb-numbers">2,500+ </span>
                <br />
              </h3>
              <p>
                <span className="blurb-text">Properties<br /> Sold</span>
              </p>
            </div>
          </div>

          <div
            className="icon-box-mobile"
            onClick={() => navigate('/about#buyer-rebates')}
            style={{ cursor: 'pointer' }}
          >
            <div className="google-icon-title-container-mobile">
              <h3 className="icon-text-mobile">
                <span className="blurb-numbers">$1.1 Million+ </span>
              </h3>
              <p>
                <span className="blurb-text">Buyers Received <br /> in Rebate</span>
              </p>
            </div>
          </div>

          <div
            className="icon-box-mobile"
            onClick={() => navigate('/about#seller-savings')}
            style={{ cursor: 'pointer' }}
          >
            <div className="google-icon-title-container-mobile">
              <h3 className="icon-text-mobile">
                <span className="blurb-numbers">$20 Million+</span>
              </h3>
              <p>
                <span className="blurb-text">Sellers Saved on<br /> Commission</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="home-page-blue-section fade-in">
        <div
          className="home-page-column fade-in"
          onClick={() => navigate('/buy')}
          style={{ cursor: 'pointer' }}
        >
          <div className="home-page-blue-icon-square">
            <i className="fas fa-home home-page-icon"></i>
          </div>
          <div className="home-page-icon-text-container">
            <h2>Buying a Home?</h2>
          </div>
          <p>
            Modern Solution agents are some of the most experienced in the real estate industry.
            They'll help you navigate today's market.
          </p>
          <button className="home-page-cta-button">
            Get $5,000 Rebate <div className="read-more-sublink">Read More</div>
          </button>
        </div>

        <div
          className="home-page-column fade-in"
          onClick={() => navigate('/sell')}
          style={{ cursor: 'pointer', marginLeft: '1rem' }}
        >
          <div className="home-page-blue-icon-square">
            <i className="fas fa-sign home-page-icon"></i>
          </div>
          <div className="home-page-icon-text-container">
            <h2>Selling Your Home?</h2>
          </div>
          <p>
            We are experts in pricing, marketing, and selling your home so you get the best results.
            Our commission is among the lowest in Ontario.
          </p>
          <button className="home-page-cta-button">
            Sell Your Home for 1% <div className="read-more-sublink">Read More</div>
          </button>
        </div>

        <div className="home-page-column contact-form-column fade-in">
          <h2 className='contact-heading'>Contact Us!</h2>
          <ContactForm />
        </div>
      </div>

      <h2 className="featured-listings-heading fade-in">Modern Solution Listings</h2>
      <ListingWidget />

      <div className="city-links-section fade-in">
        <CityWidget />
      </div>

      <div className="toronto-star-wrapper" style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="toronto-star-container" style={{ maxWidth: '1500px', display: 'flex', gap: '1rem', alignItems: 'center' }}>
          <div className="home-page-toronto-star fade-in" style={{ flex: '1' }}>
            <img src={thestarLogo} alt="The Star Logo" className="thestar-logo" />
            <h2 className="toronto-star-heading">Modern Solution Featured in Toronto Star</h2>
            <p className="toronto-star-paragraph">
              In a real estate world where outdated commission rates have long been the norm,
              Modern Solution Realty Inc. is proud to offer a refreshing and cost-effective
              alternative.

              Traditional commission structures, often hovering around five to six per cent, have
              burdened home sellers with exorbitant fees. For instance, selling a $1,000,000 home with
              a standard five per cent commission could cost a staggering $50,000 — an expense that
              seems increasingly unreasonable in today's dynamic market.
            </p>
            <a
              href="https://www.thestar.com/sponsored-sections/a-modern-solution-approach-to-real-estate-with-one-per-cent-commission/article_1ca3e32e-6eec-11ef-84ea-53f224f162c8.html"
              target="_blank"
              rel="noopener noreferrer"
              className="read-more-link"
            >
              Read More
            </a>
          </div>

          <div className="toronto-star-image-column fade-in" style={{ flex: '0.4', paddingLeft: '1rem' }}>
            <img src={require('../assets/images/thestar-office.webp')} alt="The Star Office" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
          </div>
        </div>
      </div>


      <StickyBottomBar />

      <Footer />
    </div>
  );
};

export default Home;
