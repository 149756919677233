// Sell.js

import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SEO from './SEO';
import GoogleReviews from './GoogleReviews';
import CommissionCalculator from './CommissionCalculator';
import ContactForm from './ContactForm';
import { AiFillStar } from 'react-icons/ai';
import { FaPlay } from 'react-icons/fa';
import googleLogo from '../assets/images/google-logo.webp';
import Footer from './Footer';
import './Buy.css'; // Import Buy.css for shared styles
import './Sell.css';
import ListingWidget from './ListingWidget';
import CityWidget from './CityWidget';
import Testimonials from './Testimonials';
import thestarLogo from '../assets/images/thestar-logo-2.png';
import part1 from '../assets/images/part1.jpg';
import AdvertisingPartners from './AdvertisingPartners';
import ServicesTable from './ServicesTable';
import Plotly from 'plotly.js-basic-dist';
import StickyBottomBar from './StickyBottomBar';

const Sell = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef(null);

  // Data for the alternating sections
  const slides = [
    {
      image: require('../assets/images/1.webp'),
      title: 'A Home Evaluation and Comparative Market Analysis',
      text: 'Our full-time local real estate agents will assess your home, providing a free and obligation-free written appraisal with the current market value.',
    },
    {
      image: require('../assets/images/2.webp'),
      title: 'Marketing Strategy',
      text: 'Tailoring the strategy to your property, our agents will analyze its unique features and devise a targeted marketing approach while maintaining our promise of listing your property for 1%.',
    },
    {
      image: require('../assets/images/3.webp'),
      title: 'Showing Management',
      text: 'At Modern Solution Realty, our office manages all of your showings and confirms times that work around your busy schedule. Tip: If you want to sell your property, you should allow access to as many people through the property to gain maximum exposure.',
    },
    {
      image: require('../assets/images/4.webp'),
      title: 'The Implementation of Marketing Tactics',
      text: 'We adopt a hands-on approach to every 1% listing, utilizing a range of techniques and services to secure the highest price for your property in the shortest time possible.',
    },
    {
      image: require('../assets/images/5.webp'),
      title: 'Negotiating Offers',
      text: "You won't be alone on deciding what to do when you receive an offer. Our team of agents are highly trained negotiators and will be with you every step of the way, in your corner to get the right price, terms and conditions – in short, to get the right deal for you!",
    },
    {
      image: require('../assets/images/6.webp'),
      title: 'Full MLS Exposure',
      text: 'Your property is seen through every avenue. Listed on all regional boards, the Toronto Regional Real Estate Board, House Sigma, Realtor.ca, and all platforms.',
    },
    {
      image: require('../assets/images/7.webp'),
      title: 'Sale and Closing',
      text: 'We pride ourselves on offering top-notch service from start to finish. Our realtors are available 24/7 to advise and address all your concerns. Our commitment to a great, hassle-free selling experience remains unchanged, even with your 1% listing.',
    },
    {
      image: require('../assets/images/8.webp'),
      title: 'Call Modern Solution',
      text: "Call Modern Solution Realty if you have any questions about buying or selling a home. We'd be happy to help with any of your real estate-related matters.",
    },
  ];

  const counters = [
    { icon: require('../assets/images/icon-1.webp'), value: '1%', text: 'Listings for Sellers' },
    { icon: require('../assets/images/icon-2.webp'), value: '$5,000', text: 'Rebate for buyers' },
    { icon: require('../assets/images/icon-3.webp'), value: '2500+', text: 'Properties Sold' },
    { icon: require('../assets/images/icon-4.webp'), value: '$1,100,000+', text: 'Buyers Received in Cashback' },
    { icon: require('../assets/images/icon-5.webp'), value: '$20,150,000+', text: 'Sellers Saved on Commission' },
  ];

  useEffect(() => {
    // Plotly chart code (unchanged)
    var allYears = Array.from({ length: 34 }, (x, i) => 1990 + i);
    var allOriginalPrices = [
      255020, 234313, 214971, 206490, 205448, 200220, 197760, 210695, 216017,
      228372, 243255, 251508, 275231, 293067, 315231, 335907, 351941, 376236,
      379080, 395234, 431262, 464989, 497073, 522951, 566611, 622116, 729824,
      822510, 787842, 819153, 929636, 1095475, 1190749, 1126568,
    ];

    var startIndex = allYears.findIndex((year) => year >= 2000);
    var years = allYears.slice(startIndex);
    var originalPrices = allOriginalPrices.slice(startIndex);

    var twoPercentData = originalPrices.map((price) => price * 0.01);
    var fivePercentData = originalPrices.map((price) => price * 0.025);
    var savingsData = fivePercentData.map(
      (value, index) => value - twoPercentData[index]
    );

    var trace2 = {
      x: years,
      y: twoPercentData,
      type: 'bar',
      name: 'Modern Solution 1% Commission',
      marker: {
        color: '#66c4db',
      },
      hovertemplate:
        '2.5% Commission (Outdated Commission Model): $%{customdata[1]:,.2f}<br>' +
        'Modern Solution 1% Commission: $%{y:,.2f}<br>' +
        '<br><b>On Average, Modern Solution Clients Saved: $%{customdata[0]:,.2f}</b><extra></extra>',
      customdata: savingsData.map((save, index) => [
        save,
        fivePercentData[index],
      ]),
    };

    var trace3 = {
      x: years,
      y: fivePercentData,
      type: 'bar',
      name: '2.5% Commission (Outdated Commission Model)',
      marker: {
        color: '#252525',
      },
      hoverinfo: 'skip',
    };

    var layout = {
      barmode: 'overlay',
      xaxis: {
        title: 'Year',
        titlefont: {
          family: 'Inter, sans-serif',
          size: 18,
          color: 'black',
          weight: 'bold',
        },
        tickfont: {
          size: 16,
        },
      },
      yaxis: {
        title: 'Commission Amount',
        automargin: true,
        titlefont: {
          family: 'Inter, sans-serif',
          size: 18,
          color: 'black',
          weight: 'bold',
        },
        tickfont: {
          size: 16,
        },
      },
      legend: {
        orientation: 'h',
        x: 0.5,
        y: 1.1,
        xanchor: 'center',
        yanchor: 'bottom',
        font: {
          size: 16,
        },
      },
      margin: {
        r: 10,
      },
      dragmode: false,
      scrollZoom: false,
      zoom: false,
      pan: false,
    };

    Plotly.newPlot('myCommissionChart', [trace3, trace2], layout, {
      responsive: true,
      toImageButtonOptions: {
        format: 'png',
        filename: 'custom_image',
        height: 600,
        scale: 1,
      },
      modeBarButtonsToRemove: [
        'zoom2d',
        'pan2d',
        'select2d',
        'lasso2d',
        'zoomIn2d',
        'zoomOut2d',
        'autoScale2d',
        'resetScale2d',
      ],
    });

    var averageHomePriceTrace = {
      x: years,
      y: originalPrices,
      type: 'scatter',
      mode: 'lines+markers',
      name: 'Average Home Price',
      line: {
        color: '#252525',
      },
      marker: {
        color: '#252525',
        size: 8,
      },
      hovertemplate:
        'Year: %{x}<br>' + 'Average Home Price: $%{y:,.2f}<extra></extra>',
    };

    var layout2 = {
      xaxis: {
        title: 'Year',
        titlefont: {
          family: 'Inter, sans-serif',
          size: 16,
          color: 'black',
          weight: 'bold',
        },
        tickfont: {
          size: 14,
        },
      },
      yaxis: {
        title: 'Average Home Price ($)',
        titlefont: {
          family: 'Inter, sans-serif',
          size: 16,
          color: 'black',
          weight: 'bold',
        },
        tickfont: {
          size: 14,
        },
        tickformat: '$,',
        separatethousands: true,
      },
      legend: {
        orientation: 'h',
        x: 0.5,
        y: -0.2,
        xanchor: 'center',
        yanchor: 'top',
      },
      margin: {
        t: 40,
        b: 80,
        r: 0,
        l: 120,
      },
      dragmode: false,
      scrollZoom: false,
      zoom: false,
      pan: false,
    };

    Plotly.newPlot('homePriceChart', [averageHomePriceTrace], layout2, {
      responsive: true,
    });
  }, []);

  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsVideoPlaying(true);
    }
  };

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    const elementsToAnimate = document.querySelectorAll('.animate-on-scroll');
    elementsToAnimate.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      elementsToAnimate.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  return (
    <div className="sell-page">
      <SEO
        title="Sell for 1% Commission | Low Commission Real Estate | 1 Percent List Realty"
        description="Sell your home for just 1% commission with Modern Solution. Enjoy low commission real estate services and save big. Call now!"
        keywords={[
          '1% commission real estate',
          'low commission real estate',
          'sell for 1 percent',
          '1 Percent List Realty',
          'Modern Solution Realty',
        ]}
        ogImage="https://modernsolution.ca/sell-image.jpg"
        url="https://modernsolution.ca/sell"
      />

      {/* Video Section */}
      <div className="video-container">
        {!isVideoPlaying && (
          <div className="unique-play-button" onClick={handlePlayVideo}>
            <FaPlay className="unique-play-icon" />
          </div>
        )}
        <video
          ref={videoRef}
          width="100%"
          height="auto"
          controls
          playsInline={true}
          preload="metadata"
          poster={require('../assets/images/video-poster.png')}
          onPlay={() => setIsVideoPlaying(true)}
          onPause={() => setIsVideoPlaying(false)}
        >
          <source src={require('../assets/videos/video.mp4')} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* New Full Width Section */}
      <div className="full-width-text-section animate-on-scroll">
        <h2>Sell Smart, Save Big with Modern Solution Realty</h2>
        <p>
        Stop overpaying for real estate services! Our dedicated local experts offer a game changing 1% commission and have a proven track record of outperforming the competition 4 to 1. With five-star Google reviews and over $20 million saved for our clients, we deliver results you can trust.
        </p>

        <p>
        Read to maximize your profits? Contact us today for your FREE, no-obligation home evaluation and discover how much you can save!

        </p>
      </div>

      {/* Savings Calculator Section */}
      <div className="calculator-section">
        <h2>
          On Average, our clients <span className="partners-text">saved</span> $18,000.
        </h2>
        <h3>How Much Will You Save?</h3>
        <CommissionCalculator />
      </div>

      <div className="free-home-evaluation">
      <h4 className="evaluation-text">Get a <span className="partner-text">Free </span>  Home Evaluation</h4>
      <p className='evaluation-para'>
        Want to know how much your house is worth from an experienced agent? Contact us and we will give you a free market evaluation on your home.

        </p>
        <a
        href="/contact"
        target="_blank"
        rel="noopener noreferrer"
        className="read-more-link"
      >
        Contact us for your free evaluation!
      </a>
        
        </div>
      
      
      <div className="steps-container">
      {slides.map((slide, index) => (
        index % 2 === 0 ? (
          
          
          <div key={index} className="content-image-section animate-on-scroll">
            <div className="text-content">
              <h2>{`${index + 1}. ${slide.title}`}</h2>
              <p>{slide.text}</p>
            </div>
            <div className="image-content">
              <img
                src={slide.image}
                alt={`Slide ${index + 1}`}
                className="image-placeholder"
              />
            </div>
          </div>
          
        ) : (
          // Odd index - image on left, text on right
          <div key={index} className="content-image-section text-right animate-on-scroll">
            <div className="image-content">
              <img
                src={slide.image}
                alt={`Slide ${index + 1}`}
                className="image-placeholder"
              />
            </div>
            <div className="text-content">
              <h2>{`${index + 1}. ${slide.title}`}</h2>
              <p>{slide.text}</p>
            </div>
          </div>
          
        )
      ))}
      </div>

<h2 className="graph-heading">
          Experience the <span className="partners-text">Modern Solution Marketing Advantage</span>
        </h2>



      {/* Services Table Section */}
      <ServicesTable />

      {/* Modern Solution Realty vs Other Realty Brokerages */}
      <div className="full-width-section">
        <h2 style={{ textAlign: 'center', fontSize: '2.5rem' }}>
          <span className="partners-text">Modern Solution Realty</span> vs Other Realty Brokerages
        </h2>

        <div className="charts-container">
          <div id="map-container">
            <div id="overlay">
              <div className="overlay-content">
                <div className="overlay-text">Interactive Chart - Hover/Click to see data</div>
              </div>
            </div>
            <div id="myCommissionChart"></div>
          </div>
          <div id="homePriceChart"></div>
        </div>
      </div>

      {/* Google Reviews Section */}
      <div className="google-reviews-section">
        <h2>Our Google Reviews</h2>
        <GoogleReviews />
      </div>

      {/* Counter Section */}
      <div className="counter-section">
        <h2>Results</h2>
        <div className="counter-row">
          {counters.slice(0, 3).map((counter, index) => (
            <div key={index} className="counter-box">
              <img src={counter.icon} alt={`icon-${index + 1}`} className="counter-icon" />
              <h3>{counter.value}</h3>
              <p>{counter.text}</p>
            </div>
          ))}
        </div>
        <div className="counter-row">
          {counters.slice(3, 5).map((counter, index) => (
            <div key={index} className="counter-box">
              <img src={counter.icon} alt={`icon-${index + 1}`} className="counter-icon" />
              <h3>{counter.value}</h3>
              <p>{counter.text}</p>
            </div>
          ))}
        </div>
      </div>

      

      {/* Featured Listings */}
      <h2 className="featured-listings-heading">Modern Solution Listings</h2>
      <ListingWidget />

      

      {/* City Links Section */}
      <div className="city-links-section">
        <CityWidget />
      </div>

   {/* Toronto Star Section */}
<div className="toronto-star-wrapper" style={{ display: 'flex', justifyContent: 'center' }}>
  <div className="toronto-star-container" style={{ maxWidth: '1500px', display: 'flex', gap: '1rem', alignItems: 'center' }}>
    <div className="home-page-toronto-star" style={{ flex: '1' }}>
      <img src={thestarLogo} alt="The Star Logo" className="thestar-logo" />
      <h2 className="toronto-star-heading">Modern Solution Featured in Toronto Star</h2>
      <p className="toronto-star-paragraph">
        In a real estate world where outdated commission rates have long been the norm,
        Modern Solution Realty Inc. is proud to offer a refreshing and cost-effective
        alternative.
        
        Traditional commission structures, often hovering around five to six per cent, have
        burdened home sellers with exorbitant fees. For instance, selling a $1,000,000 home with
        a standard five per cent commission could cost a staggering $50,000 — an expense that
        seems increasingly unreasonable in today’s dynamic market.
      </p>
      <a
        href="https://www.thestar.com/sponsored-sections/a-modern-solution-approach-to-real-estate-with-one-per-cent-commission/article_1ca3e32e-6eec-11ef-84ea-53f224f162c8.html"
        target="_blank"
        rel="noopener noreferrer"
        className="read-more-link"
      >
        Read More
      </a>
    </div>

    {/* New Photo Column */}
    <div className="toronto-star-image-column" style={{ flex: '0.4', paddingLeft: '1rem' }}>
      <img src={require('../assets/images/thestar-office.webp')} alt="The Star Office" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
    </div>
  </div>
</div>



      
<StickyBottomBar />
      <Footer />
    </div>
  );
};

export default Sell;
