import React, { useState } from 'react';
import './PricingYourHome.css';
import StickyBottomBar from './StickyBottomBar';

const WhyModernSolution = () => {
  const [searchParams, setSearchParams] = useState({
    minPrice: '',
    maxPrice: '',
    search: ''
  });

  const cities = [
    'Toronto', 'Mississauga', 'Brampton', 'Oakville', 'Hamilton', 
    'Vaughan', 'Waterloo', 'London', 'Markham', 'Ajax', 'Aurora', 
    'Barrie', 'Brock', 'Burlington', 'Caledon', 'Cambridge', 
    'Clarington', 'Collingwood', 'Erin', 'Georgina', 'Grimsby', 
    'Innisfil', 'King', 'Kingston', 'Lincoln', 'Midland', 'Ottawa', 
    'Milton', 'Newmarket', 'Niagara-on-the-lake', 'Orangeville', 
    'Orillia', 'Oshawa', 'Peterborough', 'Pickering', 'Tiny', 
    'Welland', 'Whitby', 'Whitchurch'
  ];

  // Keeping the same styles from the original component
  const styles = {
    container: {
      maxWidth: '1500px',
      margin: '0 auto',
      padding: '0rem 0rem',
    },
    grid: {
      gap: '2rem',
    },
    mainContent: {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
    },
    card: {
      background: 'white',
      borderRadius: '8px',
      padding: '1rem',
      marginBottom: '0rem',
    },
    sidebarCard: {
      background: '#f3f4f6',
      borderRadius: '8px',
      padding: '1.5rem',
      marginBottom: '1rem',
    },
    sidebar: {
      position: 'sticky',
      top: '1rem',
      marginTop: '2rem',
      width: '300px',
      minWidth: '300px'
    },
    list: {
      listStyleType: 'none',
      marginBottom: '1rem',
      paddingLeft: '0',
      fontSize: '1rem',
      lineHeight: '1.5',
    },
    priceInputContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      marginBottom: '0.5rem',
    },
    priceInput: {
      width: '100%',
      padding: '0.75rem',
      borderRadius: '0.25rem',
      border: '1px solid #e2e8f0',
      backgroundColor: 'white',
      fontSize: '1rem',
    },
    searchInput: {
      width: '100%',
      padding: '0.75rem',
      marginBottom: '0rem',
      borderRadius: '0.25rem',
      border: '1px solid #e2e8f0',
      backgroundColor: 'white',
      fontSize: '1rem',
    },
    cityList: {
      marginTop: '1rem',
    },
    cityLink: {
      display: 'block',
      padding: '0.5rem',
      color: '#2563eb',
      textDecoration: 'none',
      fontSize: '1.25rem',
      borderRadius: '0.25rem',
      '&:hover': {
        backgroundColor: '#f0f9ff',
      }
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const queryString = new URLSearchParams({
      city: 'All',
      community: 'null',
      minPrice: searchParams.minPrice || '0',
      maxPrice: searchParams.maxPrice || '10000000',
      bedrooms: 'Any',
      bathrooms: 'Any',
      search: searchParams.search || '',
      page: '1'
    }).toString();
    window.location.href = `/listings?${queryString}`;
  };

  const handleInputChange = (e) => {
    setSearchParams({
      ...searchParams,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="pricing-container" style={styles.container}>
      <div className="pricing-grid" style={styles.grid}>
        <div className="pricing-main-content" style={styles.mainContent}>
          <section className="pricing-hero-card" style={styles.card}>
            <h1 className="pricing-title">Why Modern Solution Realty Is the Future of Real Estate</h1>
          </section>

          <div className="pricing-content-card" style={styles.card}>
            <h2 className="pricing-subtitle">Traditional Real Estate Companies: Challenges</h2>
            
            <h3>High Commissions Without Added Value</h3>
            <p className="pricing-text">
              Traditional companies charge 5% to 6% commission rates, which are often disproportionate to the actual services provided. In a world where buyers and sellers are more informed and have access to technology, these high fees lack justification.
            </p>
            <p className="pricing-text">
              The high commission structures are outdated and do not account for the modern tools and technologies available. Consumers end up paying more for what is, in many cases, a similar level of service offered by lower-cost competitors.
            </p>

            <h3>Franchise Fees That Don't Benefit Consumers</h3>
            <p className="pricing-text">
              Traditional real estate companies often operate under franchise models, charging franchisees hefty fees for using the brand name. These fees don't improve service for consumers but instead drive up costs for the buyer or seller.
            </p>
            <p className="pricing-text">
              Franchise fees are a financial burden on franchisees, pushing up costs for consumers without offering tangible benefits, like improved customer service or enhanced market exposure.
            </p>

            <h3>Lack of Transparency</h3>
            <p className="pricing-text">
              Traditional real estate companies often have opaque pricing models, with agents charging whatever they can negotiate for commissions, leading to inconsistent costs for consumers.
            </p>

            <h3>Outdated Marketing Techniques</h3>
            <p className="pricing-text">
              Many traditional real estate companies rely on old-school marketing techniques like print ads, direct mail, and in-person open houses, which often do not have the reach or effectiveness that digital marketing strategies offer today.
            </p>

            <h3>Inefficient Technology and Poor Websites</h3>
            <p className="pricing-text">
              Traditional companies often have slow, clunky websites that don't provide real-time updates or detailed property data. Consumers today expect more—interactive listings, market insights, and instant access to important information.
            </p>
          </div>

          <div className="pricing-content-card" style={styles.card}>
            <h2 className="pricing-subtitle">Flat-Fee Real Estate Companies: Challenges</h2>

            <h3>Limited Support and Service</h3>
            <p className="pricing-text">
              Flat-fee brokerages offer minimal services for a fixed price, and many essential services—such as staging, professional photography, and negotiation assistance—are often add-ons that cost extra.
            </p>

            <h3>Minimal Marketing Exposure</h3>
            <p className="pricing-text">
              Flat-fee brokerages typically only list properties on MLS and provide minimal marketing. This lack of exposure can leave properties stagnant on the market.
            </p>

            <h3>No Negotiation Expertise</h3>
            <p className="pricing-text">
              Flat-fee agents usually do not provide the same level of negotiation expertise that full-service brokers do. Sellers may be left at a disadvantage during offer negotiations or when dealing with closing complexities.
            </p>

            <h3>Upfront Costs Can Add Up</h3>
            <p className="pricing-text">
              While flat-fee brokerages seem affordable at first, add-ons like photography, staging, and marketing can significantly increase the final cost. Sellers may think they're saving money but end up spending more than they anticipated.
            </p>
          </div>

          <div className="pricing-content-card" style={styles.card}>
            <h2 className="pricing-subtitle">Why Modern Solution Realty is the Best Option for Buyers and Sellers</h2>

            <h3>Unbeatable Service for a Fixed 1% Fee</h3>
            <p className="pricing-text">
              At Modern Solution Realty, we offer full-service real estate support for a fixed 1% listing fee. This fee covers professional staging, photography, virtual tours, and expert negotiation. No hidden costs, no surprise fees—just transparent pricing and top-tier service.
            </p>

            <h3>Comprehensive Marketing for Maximum Exposure</h3>
            <p className="pricing-text">
              We use cutting-edge digital marketing tools, including social media campaigns, targeted ads, and virtual tours, to maximize the visibility of your property. Our marketing approach ensures your property gets noticed by the right buyers, selling 32% faster than those listed with minimal marketing.
            </p>

            <h3>Technology and Transparency</h3>
            <p className="pricing-text">
              Our website is one of the most informative and interactive in the industry, providing up-to-date listings, market insights, sold history, and buyer/seller resources. We empower our clients with real-time, data-driven information, giving them the tools to make informed decisions quickly in today's competitive market.
            </p>
          </div>

          <div className="pricing-content-card" style={styles.card}>
            <h2 className="pricing-subtitle">Conclusion: Modern Solution Realty Offers the Best of All Worlds</h2>
            <p className="pricing-text">
              • Traditional real estate companies face challenges with outdated business models, high commissions, and inefficient marketing techniques.
            </p>
            <p className="pricing-text">
              • Flat-fee brokerages struggle with providing comprehensive services, expert support, and effective marketing.
            </p>
            <p className="pricing-text">
              • Modern Solution Realty combines the best of both—cutting-edge technology, full-service expertise, and affordable pricing—ensuring the best experience for buyers and sellers.
            </p>
          </div>
        </div>

        <div className="pricing-sidebar" style={styles.sidebar}>
          <div className="pricing-search-card" style={styles.sidebarCard}>
            <h3 className="pricing-sidebar-title">Search Listings</h3>
            <form onSubmit={handleSearch}>
              <div className="pricing-price-inputs" style={styles.priceInputContainer}>
                <div>
                  <input
                    type="number"
                    name="minPrice"
                    placeholder="Min Price"
                    className="pricing-input"
                    style={styles.priceInput}
                    value={searchParams.minPrice}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <input
                    type="number"
                    name="maxPrice"
                    placeholder="Max Price"
                    className="pricing-input"
                    style={styles.priceInput}
                    value={searchParams.maxPrice}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <input
                type="text"
                name="search"
                placeholder="Search by Address"
                className="pricing-search-input"
                style={styles.searchInput}
                value={searchParams.search}
                onChange={handleInputChange}
              />
              <button type="submit" className="pricing-search-button">
                Search
              </button>
            </form>
          </div>

          <div className="pricing-cities-card" style={styles.sidebarCard}>
            <h3 className="pricing-sidebar-title">Search by City</h3>
            <div className="city-list" style={styles.cityList}>
              {cities.map((city) => (
                <a
                  key={city}
                  href={`/listings?city=${city}`}
                  className="city-link"
                  style={styles.cityLink}
                >
                  {city}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <StickyBottomBar />
    </div>
  );
};

export default WhyModernSolution;