// src/components/StickyBottomBar.js
import React from 'react';
import { FaPhone, FaEnvelope } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './StickyBottomBar.css'; // Ensure this CSS file is updated accordingly

const StickyBottomBar = () => {
  const navigate = useNavigate();

  return (
    <div className="sticky-bottom-bar">
      <a href="tel:9058975000" className="sticky-button" aria-label="Call us at (905) 897-5000">
        <FaPhone className="sticky-icon" />
        <span className="button-text">(905) 897-5000</span>
      </a>
      <a href="/contact" className="sticky-button" aria-label="Email us">
        <FaEnvelope className="sticky-icon" />
        <span className="button-text">Email Us</span>
      </a>
    </div>
  );
};

export default StickyBottomBar;
