import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import icon1 from '../assets/images/icon-1.webp';
import icon3 from '../assets/images/icon-3.webp';
import icon4 from '../assets/images/icon-4.webp';
import ContactForm from './ContactForm'; // Ensure ContactForm component is imported
import './LandingPage-Selling.css';

const LandingPage = () => {
  const [buttonSet, setButtonSet] = useState('default');
  const navigate = useNavigate();

  const handleConnectWithAgentClick = () => setButtonSet('contactForm');
  const handleCommissionInfoClick = () => navigate('/commission-explained');
  const handleSellingProcessClick = () => navigate('/sell');
  const handleContactClick = () => setButtonSet('contactForm');
  const handleBackClick = () => setButtonSet('default');

  const handleIconClick = (iconType) => setButtonSet(iconType);

  return (
    <div className="landing-page">
      <header className="landing-page-header">
        <h1 className="landing-page-title">Selling Your Home?</h1>
        <h2 className="landing-page-subtitle">We've sold over 2,500 properties in Ontario</h2>
      </header>

      <div className="landing-page-content">
        {buttonSet === 'default' && (
          <>
            <button onClick={handleConnectWithAgentClick} className="landing-page-button">
              Connect me with an agent
            </button>
            <button onClick={handleCommissionInfoClick} className="landing-page-button">
              Learn about our commission structure
            </button>
            <button onClick={handleSellingProcessClick} className="landing-page-button">
              Learn about our selling process
            </button>
            <button onClick={handleContactClick} className="landing-page-button">
              Get a free home evaluation
            </button>
            <button onClick={() => window.location.href = 'tel:9058975000'} className="landing-page-button">
              Give us a call at 905-897-5000
            </button>
          </>
        )}

        {buttonSet === 'contactForm' && (
          <>
            <ContactForm />
            <button onClick={handleBackClick} className="landing-page-button back-button-custom">
              Back
            </button>
          </>
        )}

        {['propertiesSold', 'sellerSavings', 'reviews'].includes(buttonSet) && (
          <>
            <div className="icon-box-content">
              {buttonSet === 'propertiesSold' && (
                <>
                  <img src={icon1} alt="2500+ Properties Sold" className="icon-box-image-large" />
                  <h1 className="landing-page-title">2500+ Properties Sold</h1>
                  <p className="landing-page-text">
                    We've sold over 2500 properties in Ontario. Our agents have the experience to get you the best deal.
                  </p>
                </>
              )}
              {buttonSet === 'sellerSavings' && (
                <>
                  <img src={icon3} alt="$20M Sellers Saved on Commission" className="icon-box-image-large" />
                  <h1 className="landing-page-title">$20M Sellers Saved on Commission</h1>
                  <p className="landing-page-text">
                    Our 1% commission structure has saved sellers over $20M in commission fees.d
                  </p>
                </>
              )}
              {buttonSet === 'reviews' && (
                <>
                  <img src={icon4} alt="5/5 Stars - 226 Reviews" className="icon-box-image-large" />
                  <h1 className="landing-page-title">5/5 Stars - 226 Reviews</h1>
                  <p className="landing-page-text">
                    We're proud to have a 5-star rating from our clients. 
                  </p>
                </>
              )}
            </div>
            <button onClick={handleConnectWithAgentClick} className="landing-page-button">
              Connect me with an agent
            </button>
            <button onClick={handleBackClick} className="landing-page-button back-button-custom">
              Back
            </button>
          </>
        )}
      </div>

      {/* Icon Boxes */}
      <div className="icon-boxes-row">
        <div
          className="icon-box"
          onClick={() => handleIconClick('propertiesSold')}
          style={{ cursor: 'pointer' }}
        >
          <img src={icon1} alt="2500+ Properties Sold" className="icon-box-image" />
          <h3 className="icon-box-heading">2500+ Properties Sold</h3>
        </div>
        <div
          className="icon-box"
          onClick={() => handleIconClick('sellerSavings')}
          style={{ cursor: 'pointer' }}
        >
          <img src={icon3} alt="$20M Sellers Saved on Commission" className="icon-box-image" />
          <h3 className="icon-box-heading">$20M Sellers Saved on Commission</h3>
        </div>
        <div
          className="icon-box"
          onClick={() => handleIconClick('reviews')}
          style={{ cursor: 'pointer' }}
        >
          <img src={icon4} alt="5/5 Stars - 226 Reviews" className="icon-box-image" />
          <h3 className="icon-box-heading">5/5 Stars - 226 Reviews</h3>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
