import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import icon2 from '../assets/images/icon-2.webp';
import icon4 from '../assets/images/icon-4.webp';
import ContactForm from './ContactForm'; // Ensure ContactForm component is imported
import './LandingPage-Selling.css';

const LandingPage = () => {
  const [buttonSet, setButtonSet] = useState('default');
  const navigate = useNavigate();

  const handleConnectWithAgentClick = () => setButtonSet('contactForm');
  const handleBrowsePropertiesClick = () => navigate('/listings');
  const handleBuyersRebateClick = () => navigate('/buy');
  const handleBackClick = () => setButtonSet('default');

  const handleIconClick = (iconType) => setButtonSet(iconType);

  return (
    <div className="landing-page">
      <header className="landing-page-header">
        <h1 className="landing-page-title">Buying a Home?</h1>
        <h2 className="landing-page-subtitle">Save thousands by choosing Modern Solution Realty.</h2>
      </header>

      <div className="landing-page-content">
        {buttonSet === 'default' && (
          <>
            <button onClick={handleConnectWithAgentClick} className="landing-page-button">
              Connect me with an agent
            </button>
            <button onClick={handleBrowsePropertiesClick} className="landing-page-button">
              I want to browse properties
            </button>
            <button onClick={handleBuyersRebateClick} className="landing-page-button">
              Learn About the $5,000 Buyer's Rebate
            </button>
            <button onClick={() => window.location.href = 'tel:9058975000'} className="landing-page-button">
              Give us a call at 905-897-5000
            </button>
          </>
        )}

        {buttonSet === 'contactForm' && (
          <>
            <ContactForm />
            <button onClick={handleBackClick} className="landing-page-button back-button-custom">
              Back
            </button>
          </>
        )}

        {['buyerRebates', 'reviews'].includes(buttonSet) && (
          <>
            <div className="icon-box-content">
              {buttonSet === 'buyerRebates' && (
                <>
                  <img src={icon2} alt="$1.1M Buyers Received in Rebates" className="icon-box-image-large" />
                  <h1 className="landing-page-title">$1.1M Buyers Received in Rebates</h1>
                  <p className="landing-page-text">
                    Because of our 1% commission structure, we've been able to give back over $1.1M to our buyers.
                  </p>
                </>
              )}
              {buttonSet === 'reviews' && (
                <>
                  <img src={icon4} alt="5/5 Stars - 224 Reviews" className="icon-box-image-large" />
                  <h1 className="landing-page-title">5/5 Stars - 226 Reviews</h1>
                  <p className="landing-page-text">
                    We're proud to have a 5-star rating from our clients. 
                  </p>
                </>
              )}
            </div>
            <button onClick={handleConnectWithAgentClick} className="landing-page-button">
              Connect me with an agent
            </button>
            <button onClick={handleBackClick} className="landing-page-button back-button-custom">
              Back
            </button>
          </>
        )}
      </div>

      {/* Icon Boxes */}
      <div className="icon-boxes-row">
        <div
          className="icon-box"
          onClick={() => handleIconClick('buyerRebates')}
          style={{ cursor: 'pointer' }}
        >
          <img src={icon2} alt="$1.1M Buyers Received in Rebates" className="icon-box-image" />
          <h3 className="icon-box-heading">$1.1M Buyers Received in Rebates</h3>
        </div>
        <div
          className="icon-box"
          onClick={() => handleIconClick('reviews')}
          style={{ cursor: 'pointer' }}
        >
          <img src={icon4} alt="5/5 Stars - 224 Reviews" className="icon-box-image" />
          <h3 className="icon-box-heading">5/5 Stars - 224 Reviews</h3>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
